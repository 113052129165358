import { Link } from '@remix-run/react';
import { ArrowRightIcon } from 'lucide-react';
import { Button, buttonVariants } from '~/components/button';
import { Container } from '~/components/container';
import { Logo } from '~/components/logo';
import { HStack } from '~/components/stack';
import { LANDING_CTA_LINK } from '~/routes/_index/landing-cta-button';
import { ROUTES } from '~/utils/routes';

export function LandingNavbar() {
  return (
    <div className="h-landing-navbar sticky top-0 bg-card z-50">
      <Container className="h-full flex items-center justify-between">
        <Link to={ROUTES.LANDING.HERO}>
          <Logo />
        </Link>
        <HStack className="items-center gap-6 hidden lg:flex">
          <Link
            to={ROUTES.AUTH.SIGN_IN}
            className={buttonVariants({ variant: 'ghost' })}
          >
            Log in
          </Link>
          <Link to={LANDING_CTA_LINK}>
            <Button variant="theme" iconEnd={<ArrowRightIcon size={16} />}>
              Try for free
            </Button>
          </Link>
        </HStack>
      </Container>
    </div>
  );
}
